/* styles.css */
.dash-container{
  width: 100%;
  height: 100vh;
}

.card{
  background-color:#f5f5dc;
  /* background-color: #edf7f6; */
  max-width: 100%;
  height: 50vh;
  margin: 10px;
  /* justify-content: center; */
}

.card img{
  margin-top: 10px;
  max-width: 100%;
  max-height: 35vh;
  display:block;
  margin: auto;
}


.card-title{
  color: black;
  font-size: 2.5rem;
}

.flip-card-back{
  width: auto; 
  display: inline-block;
}