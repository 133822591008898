/* NewAgents.module.css */
.new-agents-heading {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .new-agents-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
  }
  
  .new-agents-table th,
  .new-agents-table td {
    border: 1px solid #111111; 
    padding: 10px;
    text-align: left;
  }
  
  .new-agents-table th {
    background-color: #d89830;
  }
  

  .new-agents-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .new-agents-table tbody tr:nth-child(odd) {
    background-color: #fff;
  }
  