/* Details.css */

.details-container {
    background-color: #E3DDDD;
    padding: 20px;
  }
  
  .center-text {
    text-align: center;
  }
  
  .heading {
    font-size: 30px;
    text-align: center;
    color: #DD2222;
    font-weight: 700;
    text-decoration: underline;
  }
  
  .bold-text {
    font-weight: bold;
    font-size: 25px;
  }
  
  .normal-text {
    font-weight: normal;
    margin-top: 20px;
  }
  
  .steps {
    margin-top: 20px;
    text-align: left;
    font-size: large;
    margin-left: 50px;
  }
  
 
ul {
    list-style-type: disc; 
    padding-left: 20px; 
  }
  
  ol {
    list-style-type: decimal;
    padding-left: 20px;
  }
  
  li {
    margin-bottom: 5px;
    font-size: 1.5rem;
  }
  
  
  ul ul {
    list-style-type: circle;
  }

  .click-reg-text {
    font-size: 1.8rem;
    background-color: #818bd7;
    color: white;
  }
  