/* Gallery.css */


.container-fluid {
    margin-top: 20px;
  }

  .container-fluid h1{
    margin-top: 15px;
    color: black;
    margin-bottom: 50px;
  }
  

  
  .img-fluid {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
    margin-bottom: 20px; 
    border: 2px solid #333; 
    border-radius: 8px; 
    transition: transform 0.3s ease-in-out; 
  }

  .img-fluid:hover {
    transform: scale(1.05); 
  }
  