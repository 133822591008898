
body,
h1,
h2,
h3,
p,
ul,
li,
figure,
figcaption,
div {
  margin: 0;
  padding: 0;
  border: 0;
}

/* Main Container */

.main-container {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 100vh;
  margin-top: 60px;
}

.main-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
  /* margin-bottom: 50px; */
}

.text-col-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%; 
  border: #bc1c1c;
}

.texts-center{
  width: 100%;
  align-items: center;
}

.head-text-container {
  position: absolute;
 width: 100%;
 align-content: center;
}

.heads-text-white {
  color: #fff;
  font-weight: 500;
  margin-bottom: 20px;
  line-height: 0.9;
  font-size: 80px;
}

.descrip-text-white {
  color: #fff;
  margin-bottom: 10px;
  font-size: 30px;
}

.register-butts {
  background-color: #e00a0a;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 25px;
  cursor: pointer;
  font-size: 25px;
  font-weight: bold;
  width: auto; 
  display: inline-block; 
}

.register-butt:hover {
  background-color: #e00a0a;
}

/* Features Container */
.features-container {
  background-color: hsl(207, 76%, 70%);
  padding: 20px;
  margin-top: 70px;
  margin-bottom: 60px;
  border: 1px solid #333;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
}

.features-container h1 {
  color: #000000;
  font-size: 4rem;
}

.features-container h3 {
  color: #0f1c57;
  font-size: 2rem;
  margin-bottom: 20px;
}

.features-container .cards-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* justify-content: center; */
  align-items: center;
  padding: 10px;
    /* margin: 10px; */
    flex: 0 0 calc(33.33% - 20px);
    justify-content: center; 
    

}

.features-container .card {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  flex: 0 0 calc(100% - 20px);
  box-sizing: border-box;
  background-color: white;
  width: 18rem;
  justify-content: center; 

}

.features-container .card img {
  display: block;
  width: 100%;
  height: auto;
  /* max-width: 100%; */
  max-height: 100px;
  object-fit: contain;
  border-radius: 20%;
  margin-bottom: 10px;
  /* margin-top: 10px;  */
  margin: 0 auto;
  align-items: center;
}

.features-container .card-body {
  width: 100%;
  /* height: auto; */
  /* max-width: 100%; */
  max-height: 150px;
  /* object-fit: contain; */
  margin-bottom: 10px;
  font-size: 1.5rem;
  /* font-weight: 600px; */
  color: #000000;
}
@media (min-width: 768px) {
  .features-container .card {
    flex: 0 0 calc(33.33% - 20px);
    max-width: calc(33.33% - 20px); 
  }
}

/* Why Container */

.why-container {
  /* background-color: #f8f8f8; */
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center; 
  /* margin-top: 100px; */
  /* border: 1px solid #333; */
  max-width: 800px;
  border-radius: 30px;
  margin-bottom: 20px;
}

.why-container .left-container {
  flex: 1;
  padding: 20px;
  align-items: center; 
}

.why-container h1 {
  margin-bottom: 20px;
  color: #e00a0a;
  font-weight: 600;
}
.why-container .left-container h3 {
  text-align: left;
  margin-top: 35px;
  color: rgb(13, 13, 77);
  list-style-type: disc; 
  padding-left: 20px; 
}

.why-bullet-list {
  list-style-type: disc;
  padding-left: 20px; 
  text-align: left;
  margin-top: 35px;
  color: rgb(13, 13, 77);
  font-size: 30px;
}

.why-container .right-container {
  margin-top: 20px; 
}

.why-container .right-container img {
  width: 100%;
  height: auto;
  max-width: 100%;
}

.card h1 {
  margin: 0;
  text-align: center;
  font-size: 3rem;
}

/* Responsive Styles */
@media (max-width: 767px) {
  .desktop-layout {
    flex-direction: column;
    text-align: center;
  }

  .right-container {
    order: -1;
    margin-top: 10px;
  }
  .left-container h3 {
    margin-top: 10px; 
  }
}

@keyframes shine {
  from {
    background-position: 200% center;
  }
  to {
    background-position: -200% center;
  }
}
.card-shine {
  position: relative;
  overflow: hidden;
}

.card-shine::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 80%);
  transform: rotate(-20deg);
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.card-shine:hover::before {
  opacity: 1;
}

.card:hover h1 {
  transform: scale(1.1); 
  transition: transform 0.3s ease-out;
}

/* Carousel Animation */
@keyframes rotateCarousel {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
  
}

/* .top-container .carousel {
  animation: rotateCarousel 20s infinite linear;
} */

