/* Navbar styling */
.navbar {
    background-color: #a21f1f !important;
  }

  
  /* Navbar brand (logo) styling */
  .navbar-dark .navbar-brand {
    color: #ffffff; 
    font-weight: bold; 
  }
  
  .navbar-dark .navbar-brand img {
    width: 120px; 
    height: auto; 
    margin-left: 30px;
  }

    
  .navbar-dark .navbar-brand .team-logo {
    width: 180px;
    height: 60px; 
    margin-left: 30px;
    
  }


  /* Navbar links styling */
  .navbar-dark .navbar-nav .nav-link {
    color: #ffffff !important; 
    margin: 0 15px; 
    font-weight: bold; 
    font-size: larger;
    margin-right: 50px; 
  }
  
  /* Navbar links hover effect */
  .navbar-dark .navbar-nav .nav-link:hover {
    color: #f8f9fa !important; 
  }
  
  /* Active link styling */
  .navbar-dark .navbar-nav .nav-link.active {
    color: #ffffff !important; 
  }
  
  /* Navbar toggle button styling */
  .navbar-dark .navbar-toggler-icon {
    background-color: #ffffff; 
  }
  
  /* Navbar toggle button hover effect */
  .navbar-dark .navbar-toggler:hover .navbar-toggler-icon {
    background-color: #ffffff; 
  }
  
  /* Navbar collapse container styling */
  .navbar-collapse {
    justify-content: flex-end; 
  }
  
  
  @media (max-width: 768px) {
    .navbar-dark .navbar-toggler-icon {
      background-color: #a21f1f; 
    }
  }