/* OldPolicyAdmin.css */

/* Style for the OldPolicyAdmin container */
.old-policy-container {
    margin: 20px;
}

/* Style for the OldPolicyAdmin table */
.old-policy-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

/* Style for the OldPolicyAdmin table header */
.old-policy-table thead {
    background-color: #fbaa39;
    color: rgb(8, 8, 8);
}

/* Style for OldPolicyAdmin table cells */
.old-policy-table th,
.old-policy-table td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ddd;
}

/* Style for alternate rows in OldPolicyAdmin table */
.old-policy-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

/* Style for hover effect on rows in OldPolicyAdmin table */
.old-policy-table tbody tr:hover {
    background-color: #ddd;
}
