.bg-image{
    background-image:url("/src/images/contact.png");
    background-size: cover;
    height: 100vh;
    /* margin-top: -70px; */
    font-size: 25px;
    background-repeat: no-repeat;
    width: 100%; 
}
/* .form {
    position: absolute;
    text-align: justify;
    top: 15%;
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    /* text-align: center;
    color: #1c2361; Set the color of your overlay text */
  /* } */ 
  
  .content h1 {
    position: default;
    font-size: 50px;
    color: #000000; 
    padding-bottom: 10px;
    background-repeat: no-repeat;
  }
  .form {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    text-align: center;
    color: #656165;
    

  }
  
  form {
    display: flex;
    flex-direction: column;
  }
/*   
  label {
    margin-top: 10px;
    font-weight: bold;
  } */
  
  input,
  textarea {
    padding: 8px;
    margin: 5px 0 15px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    background-color: #093461;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  .contact-info{
    text-align: left;
    top: 90px;
    right: 20px;
    background-color: rgba(255, 255, 255, 0.8); 
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
    border: #000000;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  @media (max-width: 600px) {
  .contact-info {
    /* position: fixed; */
    
    position: static; 
    margin-top: 20px; 
    width: 100%; 
  }
  
  .contact-info p {
    margin: 0;
  }
}
