
/* Steps Container */
.agents-steps-container {
    background-color: #ffffff;
    padding: 20px;
    border: 1px solid #333;
    margin-top: 50px;
  }
  
  .agents-steps-container h1{
   color: #000000;
   font-weight: 600px;
   font-size: 65px;
  }
  
  .agents-card-text {
    margin-top: 10px;
    font-size: 40px;
    color: #000000;
  }
  
  .agent-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .agent {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: 1px solid #cccccc;
    padding: 10px;
    margin: 10px;
    flex: 0 0 calc(33.33% - 20px);
    box-sizing: border-box;
    background-color: #C1F2B0;
    border-radius: 10px;
    height: 30vh;
    justify-content: center; 
  }
  /* .card:hover {
    background-color: #e8a28f;
  } */
  
  .steps-image {
    display: block; 
    margin: 0 auto; 
    width: 100px; 
    height: 90px; 
    object-fit: contain; 
    border-radius: 20%; 
    margin-bottom: 10px; 
    align-items: center;
  }
  
  .agent h1 {
    text-align: center;
    font-size: 40px;
    /* margin: 0; */
    margin-top: 15px;
  }
  
  
  .view-more {
    text-align: right;
    color: blue;
    font-size: 2rem;
  }
  
  .view-more a {
    color: inherit;
    text-decoration: none;
  }

  @keyframes shine {
    from {
      background-position: 200% center;
    }
    to {
      background-position: -200% center;
    }
  }
  .card-shine {
    position: relative;
    overflow: hidden;
  }
  
  .card-shine::before {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 80%);
    transform: rotate(-20deg);
    opacity: 0;
    transition: opacity 0.5s ease-out;
  }
  
  .card-shine:hover::before {
    opacity: 1;
  }
  
  .agent:hover h1 {
    transform: scale(1.1); 
    transition: transform 0.3s ease-out;
  }
  
  /* Carousel Animation */
  @keyframes rotateCarousel {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* .top-container .carousel {
    animation: rotateCarousel 20s infinite linear;
  } */
