/* Footer.css */

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-size: 14px;
  padding: 20px;
}

.footer-section {
  margin-right: 20px;
}

.footer-section h2,
.footer-section h3 {
  margin-bottom: 10px;
  font-size: 16px;
  color: #fff; 
}

.footer-section a {
  color: #fff;
  text-decoration: none;
  display: block;
  margin-bottom: 5px;
}

/* .footer-logo img {
  max-width: 100px; /* Adjust the maximum width of the logo */
/* } */ 

.footer-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-lic-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
  height: 80px;
}

.footer-team-logo {
  height: 80px;
  width: 180px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-logo p {
  margin-top: 10px;
  font-size: 12px;
}
@media (max-width: 768px) {
  footer {
    padding: 10px;
  }
}