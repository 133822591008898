/* AdminNavbar.css */

.admin-navbar {
    background-color: #1D2B53 !important;
    color: #ffffff;

}

.admin-navbar .navbar-brand {
    color: #ffffff;
    font-weight: bold; 
    font-size: 2.5rem; 
    margin-left: 50px;
}

.admin-navbar .navbar-toggler-icon {
    background-color: #ffffff;
}

.admin-navbar .nav-link {
    color: #ffffff;
    font-weight: bold; 
}

.admin-navbar .navbar-text {
    color: #ffffff;
    font: 2rem;
}

.admin-navbar .dropdown-menu {
    background-color: #ffffff;
}

.admin-navbar .dropdown-item {
    color: #000000;
    font: 1rem;
}

.admin-navbar .dropdown-item:hover {
    background-color: #ffffff;
    color: #1D2B53;
}

.admin-navbar .logout-button {
    background-color: #ff0000;
    color: #ffffff;
}

.admin-navbar .logout-button:hover {
    background-color: #cc0000;
}
