/* ContactAdmin.css */

.contact-admin-container {
    background-color: #f0f8ff; 
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .contact-admin-container h2 {
    color: #333;
  }
  
  .table {
    width: 100%;
    margin-top: 20px;
  }
  
  .table th, .table td {
    padding: 10px;
    text-align: center;
  }
  
  