/* Login.css */

.login-container {
    width: 300px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  
  .login-container h1 {
  color: rgb(10, 28, 88); 
  text-decoration: underline;
  margin-bottom: 20px;
}

  
.login-container label {
    display: block;
    margin-bottom: 5px;
    text-align: left;
  }
  
  select,
  input {
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
  }
  
  .forgot-password {
    text-align: right;
    margin-bottom: 10px;
  }
  
  .forgot-password span {
    color: #00529C;
    cursor: pointer;
  }
  
  button {
    width: 100%;
    padding: 10px;
    background-color: #00529C;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #003366;
  }
  
  .register-link {
    margin-top: 10px;
    text-align: center;
  }
  
  .register-link a {
    color: #00529C;
    text-decoration: none;
  }
  
  .register-link a:hover {
    text-decoration: underline;
  }
  