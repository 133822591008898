.background-image{
    background-image:url("/src/images/about.png");
    background-size: cover;
    height: 100vh;
    font-size: 25px;
    background-repeat: no-repeat;
    width: 100%; 
}
.content {
    position: absolute;
    text-align: justify;
    padding: 20px;
    top: 15%;
    text-align: center;
    color: #1c2361; 
    margin-bottom: 20px; 
  }
  
  .content h1 {
    position: default;
    font-size: 50px;
    color: #000000; 
    padding-bottom: 10px;
  }
  @media (max-width: 768px) {
    .content {
      padding: 40px;
      margin-bottom: 10px;
    }
  }