/* NewPolicyAdmin.css */

/* Style for the NewPolicyAdmin container */
.new-policy-container {
    margin: 20px;
}

/* Style for the NewPolicyAdmin table */
.new-policy-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

/* Style for the NewPolicyAdmin table header */
.new-policy-table thead {
    background-color: #fbaa39;
    color: rgb(0, 0, 0);
}

/* Style for NewPolicyAdmin table cells */
.new-policy-table th,
.new-policy-table td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ddd;
}

/* Style for alternate rows in NewPolicyAdmin table */
.new-policy-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

/* Style for hover effect on rows in NewPolicyAdmin table */
.new-policy-table tbody tr:hover {
    background-color: #ddd;
}
