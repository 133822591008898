/* DemoSidebar.css */

.offcanvas-body {
    padding: 15px;

  }
  
  .offcanvas-header {
    background-color: #1D2B53;
    color: #fff;
    text-align: right;
  }
  
  .offcanvas-title {
    color: #fff;
    text-align: center;
    font-size: xx-large;
  }
  
  .offcanvas-body button {
    display: block;
    width: 100%;
    margin-bottom: 35px;
    font-size: large;
    margin-top: 25px;
    background-color: #7FC7D9;
    color: black;
    font-weight: bold;
  }
    
.logout-button {
  background-color: #ff0000 !important; 
  color: #ffffff; 
  font-weight: bold;
  margin-top: 25px; 
}
